import { createSlice } from '@reduxjs/toolkit';

import AuthService from '../../services/AuthService';
import { User } from '../types';

interface EndpointAction {
    meta: {
        arg: {
            endpointName: string;
        };
    };
    payload: {
        accessToken: string;
    };
}

interface AuthState {
    identity: User | null;
    isAuth: boolean;
}

const isEndpoint = (action: EndpointAction, endpointName: string) =>
    action.meta.arg.endpointName === endpointName;

const initialState: AuthState = {
    identity: null,
    isAuth: AuthService.isAuth(),
};

const authSlice = createSlice({
    initialState,
    name: 'auth',
    reducers: {
        resetAuth(state) {
            state.isAuth = false;
            state.identity = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase('authApi/executeMutation/fulfilled', (state, action: any) => {
                if (isEndpoint(action, 'login') && action.payload.token) {
                    AuthService.setToken(action.payload.token);
                    state.isAuth = AuthService.isAuth();
                }
            })
            .addCase('accountApi/executeQuery/fulfilled', (state, action: any) => {
                if (isEndpoint(action, 'getIdentity')) {
                    state.identity = action.payload;
                }
            });
    },
});

export const { resetAuth } = authSlice.actions;

export default authSlice.reducer;
