import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../helpers/axiosBaseQuery';
import { Video, VideoPreview } from '../types';

const CANDIDATE_VIDEOS = 'candidates-video';

interface CandidateVideo extends Video {
    candidateVideoPreview: VideoPreview;
}

export interface Candidate {
    id: string;
    username: string;
    email: string;
    phoneNumber: string;
    candidateVideo: CandidateVideo;
}

interface CandidatesVideoData {
    items: Candidate[];
    totalCount: number;
}

export const candidateApi = createApi({
    reducerPath: 'candidatesApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: [CANDIDATE_VIDEOS],
    endpoints: (build) => ({
        getCandidates: build.query<CandidatesVideoData, void>({
            query: () => ({
                url: '/v1/admin/candidates/videos',
                method: 'GET',
            }),
            providesTags: (result) => {
                if (!result) {
                    return [];
                }
                return result.items.map((candidate: Candidate) => ({
                    type: CANDIDATE_VIDEOS,
                    id: candidate.candidateVideo.id,
                }));
            },
        }),
        verifyCandidateVideo: build.mutation<void, string>({
            query: (candidateVideoId) => ({
                url: `/v1/admin/candidates/video/${candidateVideoId}/verify`,
                method: 'PATCH',
            }),
            invalidatesTags: (result, error, candidateVideoId) => [
                { type: CANDIDATE_VIDEOS, id: candidateVideoId },
            ],
        }),
        rejectCandidateVideo: build.mutation<void, string>({
            query: (candidateVideoId) => ({
                url: `/v1/admin/candidates/video/${candidateVideoId}/reject`,
                method: 'PATCH',
            }),
            invalidatesTags: (result, error, candidateVideoId) => [
                { type: CANDIDATE_VIDEOS, id: candidateVideoId },
            ],
        }),
    }),
});

export const {
    useGetCandidatesQuery,
    useVerifyCandidateVideoMutation,
    useRejectCandidateVideoMutation,
} = candidateApi;
