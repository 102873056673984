import React, { FC } from 'react';

import acceptIcon from '../../assets/images/appect.svg';
import rejectIcon from '../../assets/images/reject.svg';

import styles from './VerificationButton.module.scss';
import Preloader from '../Preloader/Preloader';

interface IButtonProps {
    isAccept?: boolean;
    isLoading?: boolean;
    onClick: () => void;
}

const VerificationButton: FC<IButtonProps> = (props) => {
    const { isAccept, isLoading, onClick } = props;

    return (
        <button
            className={isAccept ? styles.accept : styles.reject}
            onClick={onClick}
            disabled={isLoading}
        >
            {isLoading ? (
                <Preloader isPrimary={isAccept} />
            ) : (
                <img src={isAccept ? acceptIcon : rejectIcon} alt="icon" />
            )}
        </button>
    );
};

export default VerificationButton;
