import { FC, ChangeEvent } from 'react';

import { ReactComponent as VolumeIcon } from '../../../../assets/icons/volume.svg';
import { ReactComponent as MuteIcon } from '../../../../assets/icons/mute.svg';

import styles from './Volume.module.scss';

interface VolumeProps {
    value: number;
    onChange: (volume: number) => void;
    onMute: () => void;
}

const Volume: FC<VolumeProps> = ({ value, onChange, onMute }) => {
    const volumeProgress = String(60 * value) + 'px'; // 60px  is  the max length of  the volume progress

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(Number(event.target.value));
    };

    return (
        <div className={styles.container}>
            <div className={styles.volume__wrapper}>
                <input
                    className={styles.volume}
                    type="range"
                    value={value}
                    min="0"
                    max="1"
                    step="0.1"
                    onChange={handleChange}
                />
                <div className={styles.progress} style={{ width: volumeProgress }} />
            </div>
            <button className={styles.icon__wrapper} onClick={onMute}>
                {value ? <VolumeIcon /> : <MuteIcon />}
            </button>
        </div>
    );
};

export default Volume;
