import { AxiosResponse, AxiosError } from 'axios';

import { axiosInstance } from './axiosInstance';

interface BaseQueryParams {
    url: string;
    method: string;
    data?: any;
}

export const axiosBaseQuery =
    () =>
    async ({ url, method, data }: BaseQueryParams) => {
        try {
            const result: AxiosResponse = await axiosInstance({ url, method, data });
            return { data: result.data };
        } catch (axiosError) {
            let err = axiosError as AxiosError;
            return {
                error: { status: err.response?.status, data: err.response?.data },
            };
        }
    };
