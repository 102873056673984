import React, { FC, useEffect, useState } from 'react';

import VerificationLayout from '../VerificationLayout/VerificationLayout';
import VideoVerification from '../VideoVerification/VideoVerification';
import PhotoVerification from '../PhotoVerification/PhotoVerification';
import {
    JobOffer,
    useVerifyJobOfferVideoMutation,
    useRejectJobOfferVideoMutation,
    useVerifyJobOfferPhotoMutation,
    useRejectJobOfferPhotoMutation,
} from '../../store/api/jobOffers.api';

interface JobOfferVerification {
    jobOffer: JobOffer;
    nextJobOfferId: string;
    onClose: () => void;
    setSelectedItem: React.Dispatch<React.SetStateAction<string>>;
}

const JobOffersVerification: FC<JobOfferVerification> = ({
    jobOffer,
    nextJobOfferId,
    onClose,
    setSelectedItem,
}) => {
    const [isError, setIsError] = useState(false);

    const [
        verifyJobOfferVideo,
        {
            isLoading: isVerifyVideoLoading,
            isSuccess: isVerifyVideoSuccess,
            isError: isVerifyVideoError,
        },
    ] = useVerifyJobOfferVideoMutation();
    const [
        rejectJobOfferVideo,
        {
            isLoading: isRejectVideoLoading,
            isSuccess: isRejectVideoSuccess,
            isError: isRejectVideoError,
        },
    ] = useRejectJobOfferVideoMutation();
    const [
        verifyJobOfferPhoto,
        {
            isLoading: isVerifyPhotoLoading,
            isSuccess: isVerifyPhotoSuccess,
            isError: isVerifyPhotoError,
        },
    ] = useVerifyJobOfferPhotoMutation();
    const [
        rejectJobOfferPhoto,
        {
            isLoading: isRejectPhotoLoading,
            isSuccess: isRejectPhotoSuccess,
            isError: isRejectPhotoError,
        },
    ] = useRejectJobOfferPhotoMutation();

    const resetError = () => setIsError(false);

    const handleVideoVerify = () => {
        resetError();
        verifyJobOfferVideo(jobOffer.jobOfferVideo.id);
    };

    const handleVideoReject = () => {
        resetError();
        rejectJobOfferVideo(jobOffer.jobOfferVideo.id);
    };

    const handlePhotoVerify = () => {
        resetError();
        verifyJobOfferPhoto(jobOffer.jobOfferPhoto.id);
    };

    const handlePhotoReject = () => {
        resetError();
        rejectJobOfferPhoto(jobOffer.jobOfferPhoto.id);
    };

    useEffect(() => {
        if (
            isVerifyVideoSuccess ||
            isRejectVideoSuccess ||
            isVerifyPhotoSuccess ||
            isRejectPhotoSuccess
        ) {
            setSelectedItem(nextJobOfferId);
        }
    }, [isVerifyVideoSuccess, isRejectVideoSuccess, isVerifyPhotoSuccess, isRejectPhotoSuccess]);

    useEffect(() => {
        if (isVerifyVideoError || isRejectVideoError || isVerifyPhotoError || isRejectPhotoError) {
            setIsError(true);
        }
    }, [isVerifyVideoError, isRejectVideoError, isVerifyPhotoError, isRejectPhotoError]);

    return (
        <VerificationLayout title={jobOffer.title} onClose={onClose}>
            {jobOffer.jobOfferVideo ? (
                <VideoVerification
                    onVerify={handleVideoVerify}
                    onReject={handleVideoReject}
                    isVerifyLoading={isVerifyVideoLoading}
                    isRejectLoading={isRejectVideoLoading}
                    isError={isError}
                    video={jobOffer.jobOfferVideo}
                    videoPreview={jobOffer.jobOfferVideo.jobOfferVideoPreview}
                    key={jobOffer.jobOfferVideo.id}
                />
            ) : (
                <PhotoVerification
                    onVerify={handlePhotoVerify}
                    onReject={handlePhotoReject}
                    isVerifyLoading={isVerifyPhotoLoading}
                    isRejectLoading={isRejectPhotoLoading}
                    isError={isError}
                    photo={jobOffer.jobOfferPhoto.location}
                    key={jobOffer.jobOfferPhoto.id}
                />
            )}
        </VerificationLayout>
    );
};

export default JobOffersVerification;
