import { ValidationError } from '../store/types';

//query responses

export const isValidationError = (value: any): value is ValidationError => {
    return value?.data?.error !== undefined;
};

// video player

export const formatVideoTime = (seconds: number): string => {
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
};

