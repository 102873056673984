import { FC, useCallback } from 'react';

import Navbar from '../Navbar/Navbar';
import AuthService from '../../services/AuthService';
import { resetAuth } from '../../store/slices/authSlice';
import { sidebarToggle } from '../../store/slices/sidebarSlice';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';

import logoPrimary from '../../assets/images/logoPrimary.svg';
import logoSecondary from '../../assets/images/logoSecondary.svg';
import arrow from '../../assets/images/arrow.svg';
import userIcon from '../../assets/images/user.svg';
import logout from '../../assets/images/logout.svg';

import styles from './Sidebar.module.scss';

const Sidebar: FC = () => {
    const dispatch = useAppDispatch();
    const userData = useAppSelector((state) => state.auth.identity);
    const isCollapsed = useAppSelector((state) => state.sidebar.isSidebarCollapsed);

    const sidebarSizeToggle = useCallback(() => {
        dispatch(sidebarToggle());
    }, []);

    const logoutHandler = () => {
        dispatch(resetAuth());
        AuthService.removeToken();
    };

    return (
        <aside className={!isCollapsed ? styles.sidebar__full : styles.sidebar__mini}>
            <button className={styles.sidebar__toggle} onClick={sidebarSizeToggle}>
                <img src={arrow} alt="icon" />
            </button>
            <div className={styles.sidebar__content}>
                <div className={styles.sidebar__header}>
                    <img
                        className={styles.logo}
                        src={!isCollapsed ? logoPrimary : logoSecondary}
                        alt="logo"
                    />
                    <Navbar isCollapsed={isCollapsed} />
                </div>
                <div className={styles.sidebar__footer}>
                    {userData && (
                        <div className={styles.user__info}>
                            <div className={styles.user__avatar}>
                                <img src={userIcon} alt="icon" />
                            </div>
                            <p className={styles.user__name}>{userData.username}</p>
                            <p className={styles.user__email}>{userData.email}</p>
                        </div>
                    )}
                    <button className={styles.logout__button} onClick={logoutHandler}>
                        <img src={logout} alt="icon" />
                        <p>Déconnexion</p>
                    </button>
                </div>
            </div>
        </aside>
    );
};

export default Sidebar;
