import { FC } from 'react';

import play from '../../assets/images/play.svg';
import pause from '../../assets/images/pause.svg';

import styles from './PlayIcon.module.scss';

interface PlayIconProps {
    playing?: boolean;
}

const PlayIcon: FC<PlayIconProps> = ({ playing }) => {
    return (
        <div className={styles.icon__wrapper}>
            <img src={playing ? pause : play} alt="icon" />
        </div>
    );
};

export default PlayIcon;
