import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../helpers/axiosBaseQuery';
import { ResponseError } from '../types';

interface LoginData {
    username: string;
    password: string;
}

interface LoginResponseSuccess {
    token: string;
}

interface LoginResponseError extends ResponseError {
    errors: {
        field: string;
        messages: string[];
    }[];
}

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        login: build.mutation<LoginResponseSuccess | LoginResponseError, LoginData>({
            query: (data) => ({
                url: `/v1/auth/login`,
                method: 'POST',
                data,
            }),
        }),
    }),
});
export const { useLoginMutation } = authApi;
