import { FC } from 'react';

import VideoPlayer from '../VideoPlayer/VideoPlayer';
import VerificationButton from '../../ui/VerificationButton/VerificationButton';
import { Video, VideoPreview } from '../../store/types';

import styles from './VideoVerification.module.scss';

interface VerificationData {
    video: Video;
    videoPreview: VideoPreview;
    onVerify: () => void;
    onReject: () => void;
    isVerifyLoading: boolean;
    isRejectLoading: boolean;
    isError: boolean;
}

const VideoVerification: FC<VerificationData> = ({
    onVerify,
    onReject,
    isVerifyLoading,
    isRejectLoading,
    isError,
    video,
    videoPreview,
}) => {
    return (
        <div className={styles.container}>
            <VideoPlayer
                videoURL={video.location}
                videoCover={videoPreview.location}
                videoDuration={video.duration}
            />
            <div className={styles.verification__buttons}>
                <VerificationButton isLoading={isRejectLoading} onClick={onReject} />
                <VerificationButton isAccept isLoading={isVerifyLoading} onClick={onVerify} />
            </div>
            {isError && <p className={styles.error__text}>Quelque chose n'a pas fonctionné!</p>}
        </div>
    );
};

export default VideoVerification;
