import React from 'react';

import AuthForm from '../../components/AuthForm/AuthForm';

import logoMain from '../../assets/images/logoAuth.svg';
import logoMobile from '../../assets/images/logoSecondary.svg';

import styles from './AuthPage.module.scss';

const AuthPage = () => {
    return (
        <div className={styles.container}>
            <div className={styles.logo__wrapper}>
                <img className={styles.logo__main} src={logoMain} alt="logo" />
                <img className={styles.logo__mobile} src={logoMobile} alt="logo" />
            </div>
            <div className={styles.form__wrapper}>
                <div className={styles.title}>
                    <h1>Content de te revoir</h1>
                    <p>Saisis tes identifiants ci-dessous </p>
                </div>
                <AuthForm />
            </div>
        </div>
    );
};

export default AuthPage;
