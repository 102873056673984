import React, { FC } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import TextInput from '../../ui/TextInput/TextInput';
import PasswordInput from '../../ui/PasswordInput/PasswordInput';
import MainButton from '../../ui/MainButton/MainButton';
import { useLoginMutation } from '../../store/api/auth.api';
import { useErrorMessage } from '../../hooks/useErrorMessage';
import { emailRegex, passwordRegex } from '../../utils/regex';

import styles from './AuthForm.module.scss';

const validationSchema = yup.object().shape({
    username: yup
        .string()
        .matches(emailRegex, 'Merci de saisir une adresse email valide')
        .required('Merci de saisir votre adresse email'),
    password: yup
        .string()
        .min(8, 'Le mot de passe est trop court')
        .max(50, 'Le mot de passe est trop long ')
        .matches(
            passwordRegex,
            'Le mot de passe doit contenir au moins une lettre, un chiffre et un caractère spécial'
        )
        .required('Merci de saisir ton mot de passe'),
});

const AuthForm: FC = () => {
    const [login, { isLoading, isError, error }] = useLoginMutation();

    const errorMessage = useErrorMessage(error, isError);

    const formik = useFormik({
        validationSchema,
        initialValues: { username: '', password: '' },
        onSubmit: (values) => {
            login({ username: values.username, password: values.password });
        },
    });

    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
            <div className={styles.inputs}>
                <TextInput
                    label="Email"
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    isTouched={formik.touched.username}
                    errorText={formik.errors.username}
                    placeholder="Saisis ton adresse email"
                    isEmail
                />
                <PasswordInput
                    label="Mot de passe"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    isTouched={formik.touched.password}
                    errorText={formik.errors.password}
                    placeholder="Saisis ton mot de passe"
                />
            </div>
            <div className={styles.button__wrapper}>
                <MainButton isPrimary isSubmit isLoading={isLoading} text="Connexion" />
                {isError && <p className={styles.error__text}>{errorMessage}</p>}
            </div>
        </form>
    );
};

export default AuthForm;
