import React, { FC } from 'react';

import Preloader from '../Preloader/Preloader';

import styles from './MainButton.module.scss';

interface IButtonProps {
    isPrimary?: boolean;
    isSubmit?: boolean;
    isLoading?: boolean;
    text: string;
}

const MainButton: FC<IButtonProps> = (props) => {
    const { isPrimary, isSubmit, isLoading, text } = props;

    return (
        <button
            className={isPrimary ? styles.primary : styles.secondary}
            disabled={isLoading}
            type={isSubmit ? 'submit' : 'button'}
        >
            {isLoading ? (
                <>
                    <Preloader isPrimary={isPrimary} />
                    <p>Chargement</p>
                </>
            ) : (
                text
            )}
        </button>
    );
};

export default MainButton;
