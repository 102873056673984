import { FC, useRef, useState, useContext, useEffect, useMemo } from 'react';
import ReactPlayer from 'react-player';

import { VideoPlayerContext } from './VideoPlayerContext';
import PlayIcon from '../../ui/PlayIcon/PlayIcon';
import PlayButton from './controllers/PlayButton/PlayButton';
import VideoProgressBar from './controllers/VideoProgressBar/VideoProgressBar';
import Volume from './controllers/Volume/Volume';

import styles from './VideoPlayer.module.scss';

export interface Video {
    videoURL: string;
    videoCover: string;
    videoDuration: number;
}

const VideoPlayer: FC<Video> = ({ videoURL, videoCover, videoDuration }) => {
    const [playing, setPlaying] = useState(false);
    const [playedSeconds, setPlayedSeconds] = useState(0);
    const [isTouched, setIsTouched] = useState(false);

    const refPlayer = useRef<ReactPlayer>(null);

    const { state, dispatch } = useContext(VideoPlayerContext);

    const isSafari = useMemo(() => {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }, []);

    const handlePlay = () => setPlaying(!playing);

    const handleProgress = (progress: { playedSeconds: number }) => {
        setPlayedSeconds(progress.playedSeconds);
    };

    const handleProgressTrack = (seconds: number) => {
        refPlayer.current?.seekTo(seconds, 'seconds');
    };

    const handleVolume = (volume: number) => {
        dispatch({ type: 'UPDATE_VOLUME', payload: volume });
    };

    const handleEnded = () => {
        setPlaying(false);
        refPlayer.current?.seekTo(0, 'seconds');
    };

    const onMute = () => dispatch({ type: 'TOGGLE_MUTED', payload: !state.isMuted });

    const volumeValue = state.isMuted ? 0 : state.volume;

    useEffect(() => {
        if (!playing && !isSafari) {
            return;
        }

        const timer = setTimeout(() => {
            const player = refPlayer.current?.getInternalPlayer();

            if (player && player.paused && !isTouched) {
                player.play();
            }
        }, 200);

        return () => clearTimeout(timer);
    }, [playing, isTouched]);

    return (
        <div className={styles.container}>
            <div className={styles.player__wrapper}>
                <ReactPlayer
                    className={styles.player}
                    url={videoURL}
                    playing={playing}
                    volume={state.volume}
                    controls={false}
                    onProgress={handleProgress}
                    progressInterval={100}
                    onStart={() => setIsTouched(true)}
                    onEnded={handleEnded}
                    ref={refPlayer}
                    playsinline
                    muted={state.isMuted}
                    light={videoCover}
                    onClickPreview={handlePlay}
                    playIcon={<PlayIcon />}
                />
                {isTouched && <PlayButton playing={playing} handlePlay={handlePlay} />}
            </div>
            <div className={styles.controllers__outer}>
                <VideoProgressBar
                    playedSeconds={playedSeconds}
                    duration={videoDuration}
                    onChange={handleProgressTrack}
                />
                <Volume value={volumeValue} onChange={handleVolume} onMute={onMute} />
            </div>
        </div>
    );
};

export default VideoPlayer;
