import { FC, ReactNode } from 'react';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

import styles from './VerificationLayout.module.scss';

interface VerificationLayoutProps {
    children: ReactNode;
    title: string;
    onClose: () => void;
}

const VerificationLayout: FC<VerificationLayoutProps> = ({ children, title, onClose }) => {
    return (
        <>
            <div className={styles.wrapper__desktop}>{children}</div>
            <div className={styles.wrapper__mobile}>
                <div className={styles.modal__container}>
                    <div className={styles.modal__header}>
                        <p>{title}</p>
                        <button onClick={onClose}>
                            <CloseIcon />
                        </button>
                    </div>
                    {children}
                </div>
            </div>
        </>
    );
};

export default VerificationLayout;
