import React, { FC, useEffect, useState } from 'react';

import VerificationLayout from '../VerificationLayout/VerificationLayout';
import VideoVerification from '../VideoVerification/VideoVerification';
import {
    Candidate,
    useRejectCandidateVideoMutation,
    useVerifyCandidateVideoMutation,
} from '../../store/api/candidates.api';

interface CandidateVerification {
    candidate: Candidate;
    nextCandidateId: string;
    onClose: () => void;
    setSelectedItem: React.Dispatch<React.SetStateAction<string>>;
}

const CandidatesVerification: FC<CandidateVerification> = ({
    candidate,
    nextCandidateId,
    onClose,
    setSelectedItem,
}) => {
    const [isError, setIsError] = useState(false);

    const [
        verifyCandidate,
        { isLoading: isVerifyLoading, isSuccess: isVerifySuccess, isError: isVerifyError },
    ] = useVerifyCandidateVideoMutation();
    const [
        rejectCandidate,
        { isLoading: isRejectLoading, isSuccess: isRejectSuccess, isError: isRejectError },
    ] = useRejectCandidateVideoMutation();

    const resetError = () => setIsError(false);

    const handleVerify = () => {
        resetError();
        verifyCandidate(candidate.candidateVideo.id);
    };

    const handleReject = () => {
        resetError();
        rejectCandidate(candidate.candidateVideo.id);
    };

    useEffect(() => {
        if (isVerifySuccess || isRejectSuccess) {
            setSelectedItem(nextCandidateId);
        }
    }, [isVerifySuccess, isRejectSuccess]);

    useEffect(() => {
        if (isVerifyError || isRejectError) {
            setIsError(true);
        }
    }, [isVerifyError, isRejectError]);

    return (
        <VerificationLayout title={candidate.username} onClose={onClose}>
            <VideoVerification
                onVerify={handleVerify}
                onReject={handleReject}
                isVerifyLoading={isVerifyLoading}
                isRejectLoading={isRejectLoading}
                isError={isError}
                video={candidate.candidateVideo}
                videoPreview={candidate.candidateVideo.candidateVideoPreview}
                key={candidate.candidateVideo.id}
            />
        </VerificationLayout>
    );
};

export default CandidatesVerification;
