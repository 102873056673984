import { FC } from 'react';

import video from '../../assets/images/videoVerification.svg';

import styles from './VideoPlaceholder.module.scss';

interface Props {
    text: string;
}

const VideoPlaceholder: FC<Props> = ({ text }) => {
    return (
        <div className={styles.container}>
            <div className={styles.icon}>
                <img src={video} alt="icon" />
            </div>
            <p>{text}</p>
        </div>
    );
};

export default VideoPlaceholder;
