import React, { FC } from 'react';
import clsx from 'clsx';

import { IInputProps } from '../types';

import envelop from '../../assets/images/envelop.svg';

import styles from './TextInput.module.scss';

const TextInput: FC<IInputProps> = (props) => {
    const { label, name, value, onChange, placeholder, isTouched, isEmail, errorText } = props;

    const textFieldStyle = clsx(styles.text__field, {
        [styles.text__field_error]: isTouched && errorText,
        [styles.text__field_email]: isEmail,
    });

    return (
        <div className={styles.container}>
            <label>
                <p className={styles.label__text}>{label}</p>
                <input
                    className={textFieldStyle}
                    type="text"
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                />
                {isEmail && <img src={envelop} alt="icon" />}
            </label>
            <p className={styles.error__text}>{isTouched && errorText}</p>
        </div>
    );
};

export default TextInput;
