import { createSlice } from '@reduxjs/toolkit';

interface SidebarState {
    isSidebarCollapsed: boolean;
}

const getInitialState = () => {
    const sidebarStore = localStorage.getItem('isSidebarCollapsed');

    return sidebarStore === 'true';
};

const initialState: SidebarState = {
    isSidebarCollapsed: getInitialState(),
};

const sidebarSlice = createSlice({
    initialState,
    name: 'sidebar',
    reducers: {
        sidebarToggle(state) {
            state.isSidebarCollapsed = !state.isSidebarCollapsed;

            localStorage.setItem('isSidebarCollapsed', `${state.isSidebarCollapsed}`);
        },
    },
});

export const { sidebarToggle } = sidebarSlice.actions;

export default sidebarSlice.reducer;
