import React, { FC, useEffect } from 'react';

import { PublicRoutes, PrivateRoutes } from './pages/routes';
import { useAppSelector } from './hooks/useAppSelector';
import { useLazyGetIdentityQuery } from './store/api/account.api';

import './App.scss';

export const RouteProvider: FC<{ isAuth: boolean }> = ({ isAuth }) => {
    if (!isAuth) {
        return <PublicRoutes />;
    }
    return <PrivateRoutes />;
};

const App: FC = () => {
    const isAuth = useAppSelector((state) => state.auth.isAuth);
    const [getIdentity] = useLazyGetIdentityQuery();

    useEffect(() => {
        if (isAuth) {
            getIdentity();
        }
    }, [isAuth]);

    return (
        <div>
            <RouteProvider isAuth={isAuth} />
        </div>
    );
};

export default App;
