import { FC } from 'react';

import Link from '../../ui/Link/Link';

import { ReactComponent as CandidatesIcon } from '../../assets/icons/candidates.svg';
import { ReactComponent as OffersIcon } from '../../assets/icons/offers.svg';

import styles from './Navbar.module.scss';

interface NavbarProps {
    isCollapsed: boolean;
}

const Navbar: FC<NavbarProps> = ({ isCollapsed }) => {
    return (
        <nav className={styles.navbar}>
            <Link
                isCollapsed={isCollapsed}
                url="/candidates"
                icon={CandidatesIcon}
                title="Candidats"
            />
            <Link isCollapsed={isCollapsed} url="/offers" icon={OffersIcon} title="Offres" />
        </nav>
    );
};

export default Navbar;
