import { useMemo } from 'react';

import { isValidationError } from '../utils/functions';

export const useErrorMessage = (error: any, isError: boolean) =>
    useMemo(() => {
        if (isValidationError(error)) {
            return error.data?.message === 'Invalid email or password'
                ? 'Email ou mot de passe invalide'
                : error.data?.message;
        }
        if (isError) {
            return "Quelque chose n'a pas fonctionné!";
        }
    }, [error, isError]);
