import { FC } from 'react';
import clsx from 'clsx';

import PlayIcon from '../../../../ui/PlayIcon/PlayIcon';

import styles from './PlayButton.module.scss';

interface PlayButtonProps {
    playing: boolean;
    handlePlay: () => void;
}

const PlayButton: FC<PlayButtonProps> = ({ playing, handlePlay }) => {
    const playButtonClassName = clsx(styles.play__button, {
        [styles.play__button_paused]: !playing,
    });

    return (
        <button className={playButtonClassName} onClick={handlePlay}>
            <PlayIcon playing={playing} />
        </button>
    );
};

export default PlayButton;
