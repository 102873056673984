import { FC, useState, useCallback } from 'react';
import clsx from 'clsx';

import { IInputProps } from '../types';

import showPassword from '../../assets/images/showPassword.svg';
import hidePassword from '../../assets/images/hidePassword.svg';

import styles from './PasswordInput.module.scss';

const PasswordInput: FC<IInputProps> = (props) => {
    const { label, name, value, onChange, placeholder, isTouched, errorText } = props;

    const [isShow, setIsShow] = useState(false);

    const passwordToggle = useCallback(() => {
        setIsShow((isShow) => !isShow);
    }, []);

    const textFieldClassName = clsx(styles.text__field, {
        [styles.text__field_error]: isTouched && errorText,
    });

    return (
        <div className={styles.container}>
            <label>
                <p className={styles.label__text}>{label}</p>
                <input
                    className={textFieldClassName}
                    type={isShow ? 'text' : 'password'}
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                />
                <img
                    className={styles.password__icon}
                    onClick={passwordToggle}
                    src={isShow ? showPassword : hidePassword}
                    alt="icon"
                />
            </label>
            <p className={styles.error__text}>{isTouched && errorText}</p>
        </div>
    );
};

export default PasswordInput;
