import React, { FC } from 'react';
import clsx from 'clsx';

import { Candidate } from '../../store/api/candidates.api';

import userIcon from '../../assets/images/user.svg';

import styles from './CandidateItem.module.scss';

interface CandidateItemProps {
    candidate: Candidate;
    isSelected: boolean;
    onSelect: (candidateId: string) => void;
}

const CandidateItem: FC<CandidateItemProps> = (props) => {
    const { candidate, isSelected, onSelect } = props;

    const handleItemClick = () => onSelect(candidate.id);

    const containerClassName = clsx({
        [styles.container__selected]: isSelected,
        [styles.container__unselected]: !isSelected,
    });

    return (
        <div className={containerClassName} onClick={handleItemClick}>
            <div className={styles.avatar}>
                <img src={userIcon} alt="icon" />
            </div>
            <div className={styles.typography}>
                <p className={styles.name}>{candidate.username}</p>
                <p className={styles.email}>{candidate.email}</p>
                <p className={styles.phone}>{candidate.phoneNumber}</p>
            </div>
        </div>
    );
};

export default CandidateItem;
