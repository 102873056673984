import axios from 'axios';

import { apiUrl } from '../utils/environment';

export const axiosInstance = axios.create({ baseURL: apiUrl });

const getAccessToken = () => {
    const userData = localStorage.getItem('userData');
    if (!userData) {
        return null;
    }
    try {
        return JSON.parse(userData).token;
    } catch (err) {
        return null;
    }
};

const requestInterceptor = async (config: any) => {
    const accessToken = getAccessToken();
    if (accessToken) {
        config.headers = { accessToken };
    }
    return config;
};

axiosInstance.interceptors.request.use(requestInterceptor);
