import { bindActionCreators } from '@reduxjs/toolkit';
import { Store } from 'redux';
import { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

import AuthService from '../services/AuthService';
import { resetAuth } from '../store/slices/authSlice';

const identity = (id: any) => id;
const getErrorData = (err: any) => err && err.response && err.response.data;
const isAuthError = (data: any) => data && (data.statusCode === 401 || data.statusCode === 403);

export const useAuth = (axios: AxiosInstance, store: Store) => {
    const actions = bindActionCreators({ resetAuth }, store.dispatch);
    axios.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
        if (AuthService.isAuth()) {
            config.headers.Authorization = 'Bearer ' + AuthService.getToken();
        }

        return config;
    });

    axios.interceptors.response.use(identity, (error) => {
        const data = getErrorData(error);
        if (isAuthError(data)) {
            actions.resetAuth();
            AuthService.removeToken();
        }

        throw error;
    });
};
