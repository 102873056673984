import React, { FC, useState } from 'react';
import { Outlet } from 'react-router';

import Sidebar from '../Sidebar/Sidebar';

import logoMobile from '../../assets/images/logoSecondary.svg';
import burger from '../../assets/images/burger.svg';
import close from '../../assets/icons/close.svg';

import styles from './CabinetLayout.module.scss';

const CabinetLayout: FC = () => {
    const [isSidebarShown, setIsSidebarShown] = useState(false);

    const sidebarToggle = () => setIsSidebarShown(!isSidebarShown);

    const handleOverlayClick = isSidebarShown ? sidebarToggle : undefined;

    return (
        <div className={styles.container}>
            <div
                className={isSidebarShown ? styles.blur : styles.clear}
                onClick={handleOverlayClick}
            >
                <button onClick={sidebarToggle}>
                    <img src={close} alt="icon" />
                </button>
            </div>
            <header className={styles.header}>
                <img className={styles.logo__mobile} src={logoMobile} alt="logo" />
                {!isSidebarShown && (
                    <button onClick={sidebarToggle}>
                        <img src={burger} alt="icon" />
                    </button>
                )}
            </header>
            <div
                className={
                    isSidebarShown ? styles.sidebar__wrapper_shown : styles.sidebar__wrapper_hidden
                }
            >
                <Sidebar />
            </div>
            <div className={styles.content__wrapper}>
                <Outlet />
            </div>
        </div>
    );
};

export default CabinetLayout;
