import React, { FC } from 'react';
import clsx from 'clsx';

import { JobOffer } from '../../store/api/jobOffers.api';

import userIcon from '../../assets/images/user.svg';

import styles from './JobOfferItem.module.scss';

interface JobOfferItemProps {
    jobOffer: JobOffer;
    isSelected: boolean;
    onSelect: (jobOfferId: string) => void;
}

const JobOfferItem: FC<JobOfferItemProps> = (props) => {
    const { jobOffer, isSelected, onSelect } = props;

    const handleItemClick = () => onSelect(jobOffer.id);

    const containerClassName = clsx({
        [styles.container__selected]: isSelected,
        [styles.container__unselected]: !isSelected,
    });

    return (
        <div className={containerClassName} onClick={handleItemClick}>
            <div className={styles.logo}>
                {jobOffer.companyProfile.companyLogo ? (
                    <img
                        className={styles.logo__company}
                        src={jobOffer.companyProfile.companyLogo.location}
                        alt="icon"
                    />
                ) : (
                    <img src={userIcon} alt="icon" />
                )}
            </div>
            <div className={styles.typography}>
                <p className={styles.title}>{jobOffer.title}</p>
                <div className={styles.company}>{jobOffer.companyProfile.name}</div>
                <p className={styles.email}>{jobOffer.companyProfile.user.email}</p>
            </div>
        </div>
    );
};

export default JobOfferItem;
