import { FC } from 'react';

import { ReactComponent as PreloaderIcon } from '../../assets/icons/preloader.svg';

import styles from './Preloader.module.scss';

interface PreloaderProps {
    isPrimary?: boolean;
}

const Preloader: FC<PreloaderProps> = ({ isPrimary }) => {
    const preloaderClass = isPrimary ? styles.preloader__primary : styles.preloader__secondary;

    return <PreloaderIcon className={preloaderClass} />;
};

export default Preloader;
