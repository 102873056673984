import React, { FC, FunctionComponent } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import styles from './Link.module.scss';

interface LinkProps {
    isCollapsed: boolean;
    url: string;
    icon: FunctionComponent;
    title: string;
}

const Link: FC<LinkProps> = (props) => {
    const { isCollapsed, url, icon: Icon, title } = props;

    const location = useLocation();

    const isActiveLink = location.pathname === url;

    const linkClassName = clsx({
        [styles.link]: !isCollapsed,
        [styles.link__collapsed]: isCollapsed,
        [styles.link__active]: isActiveLink,
    });

    return (
        <NavLink to={url} className={linkClassName}>
            <Icon />
            <p>{title}</p>
        </NavLink>
    );
};

export default Link;
