import { FC, useMemo, useState } from 'react';

import CandidateItem from '../../components/CandidateItem/CandidateItem';
import Skeleton from '../../ui/Skeleton/Skeleton';
import VideoPlaceholder from '../../ui/VideoPlaceholder/VideoPlaceholder';
import CandidatesVerification from '../../components/CandidatesVerification/CandidatesVerification';
import { VideoPlayerContextProvider } from '../../components/VideoPlayer/VideoPlayerContext';
import { useGetCandidatesQuery, Candidate } from '../../store/api/candidates.api';

import styles from './Candidates.module.scss';

const Candidates: FC = () => {
    const [selectedItem, setSelectedItem] = useState('');
    const { data: candidates } = useGetCandidatesQuery();

    const candidate = useMemo<Candidate | undefined>(() => {
        if (candidates) {
            return candidates.items.find((candidate: Candidate) => candidate.id === selectedItem);
        }
    }, [candidates, selectedItem]);

    const nextCandidateId = useMemo<string>(() => {
        if (candidates && candidate) {
            const currentIndex = candidates.items.indexOf(candidate);
            const nextCandidate = candidates.items[currentIndex + 1];

            if (currentIndex === candidates.items.length - 1) {
                return '';
            }

            return nextCandidate.id;
        }
        return '';
    }, [candidates, candidate]);

    const handleSelect = (candidateId: string) => {
        if (selectedItem === candidateId) {
            setSelectedItem('');
        } else {
            setSelectedItem(candidateId);
        }
    };

    const handleClose = () => {
        setSelectedItem('');
    };

    if (!candidates) {
        return (
            <div className={styles.container}>
                <h1>Vérification des candidatures vidéo</h1>
                <div className={styles.content}>
                    <div className={styles.candidates__list_wrapper}>
                        <div className={styles.candidates__list}>
                            <Skeleton />
                        </div>
                    </div>
                    <VideoPlaceholder text="Aucune vidéo sélectionnée" />
                </div>
            </div>
        );
    }

    return (
        <VideoPlayerContextProvider>
            <div className={styles.container}>
                <h1>Vérification des candidatures vidéo</h1>
                {candidates.items.length === 0 ? (
                    <VideoPlaceholder text="Aucune vidéo à vérifier" />
                ) : (
                    <div className={styles.content}>
                        <div className={styles.candidates__list_wrapper}>
                            <div className={styles.title}>
                                <h2>Nouvelles vidéos</h2>
                                <div className={styles.candidates__amount}>
                                    {candidates.totalCount}
                                </div>
                            </div>
                            <div className={styles.candidates__list}>
                                {candidates.items.map((candidate: Candidate) => (
                                    <CandidateItem
                                        candidate={candidate}
                                        isSelected={candidate.id === selectedItem}
                                        onSelect={handleSelect}
                                        key={candidate.id}
                                    />
                                ))}
                            </div>
                        </div>
                        {candidate ? (
                            <CandidatesVerification
                                candidate={candidate}
                                nextCandidateId={nextCandidateId}
                                onClose={handleClose}
                                setSelectedItem={setSelectedItem}
                                key={candidate.id}
                            />
                        ) : (
                            <VideoPlaceholder text="Aucune vidéo sélectionnée" />
                        )}
                    </div>
                )}
            </div>
        </VideoPlayerContextProvider>
    );
};

export default Candidates;
