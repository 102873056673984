import { FC, useReducer, ReactNode, createContext, Dispatch } from 'react';

interface VolumeState {
    volume: number;
    isMuted: boolean;
}

interface MuteAction {
    type: 'TOGGLE_MUTED';
    payload: boolean;
}

interface VolumeAction {
    type: 'UPDATE_VOLUME';
    payload: number;
}

const initialState = {
    volume: 1,
    isMuted: false,
};

const reducer = (state: VolumeState, action: MuteAction | VolumeAction) => {
    switch (action.type) {
        case 'TOGGLE_MUTED':
            return { isMuted: action.payload, volume: state.volume };
        case 'UPDATE_VOLUME':
            return { isMuted: action.payload === 0, volume: action.payload };
    }
};

export const VideoPlayerContext = createContext<{
    state: VolumeState;
    dispatch: Dispatch<MuteAction | VolumeAction>;
}>({
    state: initialState,
    dispatch: () => {},
});

interface VideoPlayerContextProviderProps {
    children: ReactNode;
}

export const VideoPlayerContextProvider: FC<VideoPlayerContextProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <VideoPlayerContext.Provider value={{ state, dispatch }}>
            {children}
        </VideoPlayerContext.Provider>
    );
};
