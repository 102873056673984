import { FC } from 'react';

import styles from './Skeleton.module.scss';

const Skeleton: FC = () => {
    return (
        <div className={styles.container}>
            <div className={styles.title__block} />
            <div className={styles.main__block} />
            <div className={styles.main__block} />
            <div className={styles.main__block} />
            <div className={styles.main__block} />
            <div className={styles.main__block} />
            <div className={styles.main__block} />
        </div>
    );
};

export default Skeleton;
