import { FC, useMemo, useState } from 'react';

import { VideoPlayerContextProvider } from '../../components/VideoPlayer/VideoPlayerContext';
import Skeleton from '../../ui/Skeleton/Skeleton';
import { JobOffer, useGetJobOffersQuery } from '../../store/api/jobOffers.api';
import VideoPlaceholder from '../../ui/VideoPlaceholder/VideoPlaceholder';
import JobOfferItem from '../../components/JobOfferItem/JobOfferItem';
import JobOffersVerification from '../../components/JobOffersVerification/JobOffersVerification';

import styles from './JobOffers.module.scss';

const JobOffers: FC = () => {
    const [selectedItem, setSelectedItem] = useState('');
    const { data: jobOffersData } = useGetJobOffersQuery();

    const jobOffer = useMemo<JobOffer | undefined>(() => {
        if (jobOffersData) {
            return jobOffersData.items.find((jobOffer: JobOffer) => jobOffer.id === selectedItem);
        }
    }, [jobOffersData, selectedItem]);

    const nextJobOfferId = useMemo<string>(() => {
        if (jobOffersData && jobOffer) {
            const currentIndex = jobOffersData.items.indexOf(jobOffer);
            const nextJobOffer = jobOffersData.items[currentIndex + 1];

            if (currentIndex === jobOffersData.items.length - 1) {
                return '';
            }

            return nextJobOffer.id;
        }
        return '';
    }, [jobOffersData, jobOffer]);

    const handleSelect = (jobOfferId: string) => {
        if (selectedItem === jobOfferId) {
            setSelectedItem('');
        } else {
            setSelectedItem(jobOfferId);
        }
    };

    const handleClose = () => {
        setSelectedItem('');
    };

    if (!jobOffersData) {
        return (
            <div className={styles.container}>
                <h1>Vérification des offres d'emploi</h1>
                <div className={styles.content}>
                    <div className={styles.offers__list_wrapper}>
                        <div className={styles.offers__list}>
                            <Skeleton />
                        </div>
                    </div>
                    <VideoPlaceholder text="Aucun média sélectionné" />
                </div>
            </div>
        );
    }

    return (
        <VideoPlayerContextProvider>
            <div className={styles.container}>
                <h1>Vérification des offres d'emploi</h1>
                {jobOffersData.items.length === 0 ? (
                    <VideoPlaceholder text="Aucun média à vérifier" />
                ) : (
                    <div className={styles.content}>
                        <div className={styles.offers__list_wrapper}>
                            <div className={styles.title}>
                                <h2>Nouvelle offre</h2>
                                <div className={styles.offers__amount}>
                                    {jobOffersData.totalCount}
                                </div>
                            </div>
                            <div className={styles.offers__list}>
                                {jobOffersData.items.map((jobOffer: JobOffer) => (
                                    <JobOfferItem
                                        jobOffer={jobOffer}
                                        isSelected={jobOffer.id === selectedItem}
                                        onSelect={handleSelect}
                                        key={jobOffer.id}
                                    />
                                ))}
                            </div>
                        </div>
                        {jobOffer ? (
                            <JobOffersVerification
                                jobOffer={jobOffer}
                                nextJobOfferId={nextJobOfferId}
                                onClose={handleClose}
                                setSelectedItem={setSelectedItem}
                                key={jobOffer.id}
                            />
                        ) : (
                            <VideoPlaceholder text="Aucun média sélectionné" />
                        )}
                    </div>
                )}
            </div>
        </VideoPlayerContextProvider>
    );
};

export default JobOffers;
