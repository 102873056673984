import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../helpers/axiosBaseQuery';
import { ResponseError, User } from '../types';

export const accountApi = createApi({
    reducerPath: 'accountApi',
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        getIdentity: build.query<User | ResponseError, void>({
            query: () => ({
                url: `/v1/admin/users/me`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useLazyGetIdentityQuery } = accountApi;
