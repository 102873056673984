/* eslint-disable react-hooks/rules-of-hooks */

import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import { authApi } from './api/auth.api';
import { accountApi } from './api/account.api';
import { candidateApi } from './api/candidates.api';
import { jobOffersApi } from './api/jobOffers.api';
import authSlice from './slices/authSlice';
import sidebarSlice from './slices/sidebarSlice';
import { axiosInstance } from '../helpers/axiosInstance';
import { useAuth } from '../helpers/axiosMiddleware';

const reducer = combineReducers({
    auth: authSlice,
    sidebar: sidebarSlice,
    [authApi.reducerPath]: authApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [candidateApi.reducerPath]: candidateApi.reducer,
    [jobOffersApi.reducerPath]: jobOffersApi.reducer,
});

export const store = configureStore({
    reducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            authApi.middleware,
            accountApi.middleware,
            candidateApi.middleware,
            jobOffersApi.middleware
        ),
});

useAuth(axiosInstance, store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
