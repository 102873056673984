import { FC } from 'react';

import VerificationButton from '../../ui/VerificationButton/VerificationButton';

import styles from './PhotoVerification.module.scss';

interface VerificationData {
    photo: string;
    onVerify: () => void;
    onReject: () => void;
    isVerifyLoading: boolean;
    isRejectLoading: boolean;
    isError: boolean;
}

const PhotoVerification: FC<VerificationData> = ({
    photo,
    onVerify,
    onReject,
    isVerifyLoading,
    isRejectLoading,
    isError,
}) => {
    return (
        <div className={styles.container}>
            <img className={styles.photo} src={photo} alt="pic"/>
            <div className={styles.verification__buttons}>
                <VerificationButton isLoading={isRejectLoading} onClick={onReject} />
                <VerificationButton isAccept isLoading={isVerifyLoading} onClick={onVerify} />
            </div>
            {isError && <p className={styles.error__text}>Quelque chose n'a pas fonctionné!</p>}
        </div>
    );
};

export default PhotoVerification;
