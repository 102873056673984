import { Route, Routes, Navigate } from 'react-router';

import AuthPage from './AuthPage/AuthPage';
import CabinetLayout from '../components/CabinetLayout/CabinetLayout';
import Candidates from './Candidates/Candidates';
import JobOffers from './JobOffers/JobOffers';

export const PublicRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<AuthPage />} />
            <Route index element={<Navigate to="login" />} />
            <Route path="login" element={<AuthPage />} />
            <Route path="*" element={<Navigate to="login" replace />} />
        </Routes>
    );
};

export const PrivateRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<CabinetLayout />}>
                <Route index element={<Navigate to="candidates" />} />
                <Route path="candidates" element={<Candidates />} />
                <Route path="offers" element={<JobOffers />} />
                <Route path="*" element={<Navigate to="candidates" />} />
            </Route>
        </Routes>
    );
};
