import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../helpers/axiosBaseQuery';
import { Photo, Video, VideoPreview } from '../types';

const JOB_OFFER_MEDIA = 'job-offer-media';

interface CompanyLogo {
    id: string;
    location: string;
}

interface CompanyUser {
    email: string;
}

interface CompanyProfile {
    id: string;
    name: string;
    companyLogo: CompanyLogo;
    user: CompanyUser;
}

interface JobOfferVideo extends Video {
    jobOfferVideoPreview: VideoPreview;
}

export interface JobOffer {
    id: string;
    title: string;
    description: string;
    jobOfferPhoto: Photo;
    jobOfferVideo: JobOfferVideo;
    companyProfile: CompanyProfile;
}

interface JobOffersData {
    items: JobOffer[];
    totalCount: number;
}

export const jobOffersApi = createApi({
    reducerPath: 'jobOffersApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: [JOB_OFFER_MEDIA],
    endpoints: (build) => ({
        getJobOffers: build.query<JobOffersData, void>({
            query: () => ({
                url: '/v1/admin/job-offers/media',
                method: 'GET',
            }),
            providesTags: (result) => {
                if (!result) {
                    return [];
                }
                return result.items.map((jobOffer: JobOffer) => ({
                    type: JOB_OFFER_MEDIA,
                    id: jobOffer.jobOfferVideo
                        ? jobOffer.jobOfferVideo.id
                        : jobOffer.jobOfferPhoto.id,
                }));
            },
        }),
        verifyJobOfferVideo: build.mutation<void, string>({
            query: (jobOfferVideoId) => ({
                url: `/v1/admin/job-offers/video/${jobOfferVideoId}/verify`,
                method: 'PATCH',
            }),
            invalidatesTags: (result, error, jobOfferVideoId) => [
                { type: JOB_OFFER_MEDIA, id: jobOfferVideoId },
            ],
        }),
        rejectJobOfferVideo: build.mutation<void, string>({
            query: (jobOfferVideoId) => ({
                url: `/v1/admin/job-offers/video/${jobOfferVideoId}/reject`,
                method: 'PATCH',
            }),
            invalidatesTags: (result, error, jobOfferVideoId) => [
                { type: JOB_OFFER_MEDIA, id: jobOfferVideoId },
            ],
        }),
        verifyJobOfferPhoto: build.mutation<void, string>({
            query: (jobOfferPhotoId) => ({
                url: `/v1/admin/job-offers/photo/${jobOfferPhotoId}/verify`,
                method: 'PATCH',
            }),
            invalidatesTags: (result, error, jobOfferPhotoId) => [
                { type: JOB_OFFER_MEDIA, id: jobOfferPhotoId },
            ],
        }),
        rejectJobOfferPhoto: build.mutation<void, string>({
            query: (jobOfferPhotoId) => ({
                url: `/v1/admin/job-offers/photo/${jobOfferPhotoId}/reject`,
                method: 'PATCH',
            }),
            invalidatesTags: (result, error, jobOfferPhotoId) => [
                { type: JOB_OFFER_MEDIA, id: jobOfferPhotoId },
            ],
        }),
    }),
});

export const {
    useGetJobOffersQuery,
    useVerifyJobOfferVideoMutation,
    useRejectJobOfferVideoMutation,
    useVerifyJobOfferPhotoMutation,
    useRejectJobOfferPhotoMutation,
} = jobOffersApi;
