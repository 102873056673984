import { FC, ChangeEvent, useMemo } from 'react';

import { formatVideoTime } from '../../../../utils/functions';

import styles from './VideoProgressBar.module.scss';

interface VideoProgressBarProps {
    playedSeconds: number;
    duration: number;
    onChange: (seconds: number) => void;
}

const VideoProgressBar: FC<VideoProgressBarProps> = ({ playedSeconds, duration, onChange }) => {
    const progressTrackStep = useMemo(() => duration / 100, [duration]);
    const formattedDuration = useMemo(() => formatVideoTime(duration), [duration]);
    const videoProgress = String((playedSeconds / duration) * 100) + '%';

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(Number(event.target.value));
    };

    return (
        <div className={styles.container}>
            <p>{formatVideoTime(playedSeconds)}</p>
            <div className={styles.progressbar__wrapper}>
                <input
                    className={styles.progressbar}
                    type="range"
                    value={playedSeconds}
                    min="0"
                    max={duration}
                    step={progressTrackStep}
                    onChange={handleChange}
                />
                <div className={styles.progress} style={{ width: videoProgress }} />
            </div>
            <div />
            <p>{formattedDuration}</p>
        </div>
    );
};

export default VideoProgressBar;
